import React, { useState } from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from "@reach/router"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const NavMenu = () => {
  const [isVisible, setVisibility] = useState(false)
  let location = useLocation();

  const { menuItems } = useSiteMetadata()
  
  let showStyle = null;
  if (isVisible){
    showStyle = {transform: 'scaleY(1)', display: 'block'}
  }else{
    showStyle = {display: 'none'}
  }
  
  const url = location.pathname.split('/')[1];
  const pathname = url === '' ? '/' : url;

  return(
    <nav id="nav-wrap">
      
     <button
        id="toggle-btn"
        href="/#"
        title="Menu"
        style={{width: "20px"}}
        onClick={() => setVisibility(!isVisible)}
        >
        <FontAwesomeIcon icon={ faBars } />
      </button> 
   <ul className="nav mobile" style={showStyle}>
     
        {menuItems.map((value, index) => {
          return (
            <li key={index}>
              <button className={`navButton ${pathname === value.path ? 'selected' : ''}`} onClick={() => navigate('/' + value.path)}>
                {value.label}
              </button>
            </li>
          )
        })}
    <li className="buttons navlitrial" style={{float: "right", marginTop: "8px", marginRight: '0px'}}>
      <AnchorLink className="button navtrial" to="/#call-to-action2" >Kontaktirajte nas</AnchorLink>
    </li>
     
   </ul>
    </nav>
  )
}
export default NavMenu;