import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useLocation } from "@reach/router"
import NavMenu from "./navmenu"

const Header = () => {
  const [pathname, setPathname] = React.useState('/');
  let location = useLocation();
  const logo = useSiteMetadata().logo;
  
  React.useEffect(()=>{
    const url = location.pathname.split('/')[1];
    setPathname(url === '' ? '/' : url);
  }, [location]);
  
  return <header>
    <div className="logo">
      {pathname === '/' && <AnchorLink to="/">
        <img alt="" src={logo}/>
      </AnchorLink>}
      {pathname !== '/' && <AnchorLink to="/">
        <img alt="" src={logo}/>
      </AnchorLink>}
    </div>

    <NavMenu/>

  </header>
}
export default Header;